<template>
    <section>
        <modal-lateral ref="modalInformacionCompra" titulo="Información de la compra" class="background-edit">
            <div>
                <div class="row mx-0 mt-3 f-14">
                    <div class="col">
                        <p class="text-center text-general f-600">
                            Cantidad
                        </p>
                        <p class="text-center text-general">
                            {{ agregarSeparadoresNumero(compra.cantidad, cantidad_tipo == 1 ? 0 : 2) }}
                        </p>
                    </div>
                    <div class="col">
                        <p class="text-center text-general f-600">
                            Valor U.
                        </p>
                        <p class="text-center text-general">
                            {{ convertMoney(compra.valor,compra.id_moneda) }}
                        </p>
                    </div>
                    <div class="col">
                        <p class="text-center text-general f-600">
                            Fecha
                        </p>
                        <p class="text-center text-general">
                            {{ compra.created_at_format }}
                        </p>
                    </div>
                    <div v-if="compra.id_proveedor" class="col">
                        <p class="text-center text-general f-600">
                            Proveedor
                        </p>
                        <p class="text-center text-general tres-puntos">
                            {{ compra.nombre_proveedor }}
                        </p>
                    </div>
                    <div v-if="compra.vencimiento" class="col">
                        <p class="text-center text-general f-600">
                            Vencimiento
                        </p>
                        <p class="text-center text-general tres-puntos">
                            {{ compra.vencimiento }}
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-3 f-14">
                    <div class="col-auto pr-0">
                        <img :src="compra.imagen" width="42" height="42" class="border obj-cover rounded-circle" />
                    </div>
                    <div class="col pt-2">
                        <div class="border bg-white message position-relative">
                            <!-- Triangle -->
                            <div class="arrow-left" />
                            <!-- Triangle -->
                            <p class="f-500 px-3 py-1 text-general">
                                {{ compra.nombre }}
                            </p>
                            <p class="px-3 text-general">
                                {{ compra.comentario }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-12 text-general text-center f-600">
                        Movimientos
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 342px);">
                    <div v-for="(item, idx) in productos_stocks_retiros" :key="idx" class="row mx-0 mt-2">
                        <div class="col-auto pr-0">
                            <img :src="item.imagen" width="42" height="42" class="border obj-cover rounded-circle" />
                        </div>
                        <div class="col">
                            <div class="border bg-white p-1 message position-relative f-14">
                                <div class="arrow-left" />
                                <div class="row mx-0 d-middle text-general justify-content-between">
                                    <p class="col pl-0">
                                        <span class="f-600 mr-2 f-14"> {{ fun_tipo(item.tipo) }} </span>
                                        {{ agregarSeparadoresNumero(item.cantidad, 2) }} Uni.
                                    </p>
                                    <p class="text-right f-14">
                                        {{ formatearFecha(item.created_at) }}
                                    </p>
                                </div>
                                <div v-if="item.tipo == 2 || item.tipo == 3" class="row mx-0 d-middle">
                                    <p class="col pl-0 text-general">
                                        <span class="f-600 mr-2 f-14">Cedis</span>
                                        {{ item.nombre_cedis }}
                                    </p>
                                </div>
                                <div class="row mx-0">
                                    <p class="col-12 px-0 mb-2 f-500 text-general">
                                        {{ item.nombre }}
                                    </p>
                                    <p class="text-general" v-html="formatearTextoHtml(item.comentario)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import ProductosStocks from '~/services/productos/productos_stocks'
export default {
    props:{
        admin:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            productos_stocks_retiros: [],
            cantidad_tipo: 0,
            compra:{
                id: null,
                cant_ajuste: false,
                cantidad: 0,
                cantidad_restante: 0,
                comentario: "",
                created_at_format: "",
                fecha_evaluar: "",
                id: null,
                valor: 0,
                imagen: null,
                nombre: ''
            },
            card:{

            }
        }
    },
    methods: {
        toggle(info, card, cantidad_tipo){
            this.compra = info
            this.cantidad_tipo = cantidad_tipo
            console.log('load', this.compra);
            this.card = card
            this.listaStocksRetiros()
            this.$refs.modalInformacionCompra.toggle();
        },
        async  listaStocksRetiros(){
            try {
                const params = {
                    id_stock: this.compra.id
                }
                const {data} = await ProductosStocks.listaStockRetiros(params)
                this.productos_stocks_retiros = data.productos_stocks_retiros
            } catch (e){
                this.error_catch(e)
            }

        },
        fun_tipo(tipo){
            switch (tipo){
            case 1:
                return "Retiro"
            case 2:
                return "Traslado"
            case 3:
                return "Devolución"
            default:
                return "Retiro"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.message{
    min-height: 100px;
    border-radius: 0px 4px 4px 4px;
}
.arrow-left{
  position: absolute;
  left: -10px;
  top: 0px;
  width: 0;
  height: 10;
  border-top: 1px solid #fff;
  border-bottom: 10px solid transparent;
  border-right:10px solid #fff;
}
</style>
